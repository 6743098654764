import {setLocale as setVeeLocale} from "@vee-validate/i18n";

export const useLanguage = () => {
    const {setLocale: setI18nLocale, locale} = useI18n({useScope: 'global'})

    const lang = useLocalStorage('lang', locale.value)
    const isChangingLang = ref(false)

    const toggle = computed({
        get: () => lang.value === 'en',
        set: async (value) => {
            lang.value = value ? 'en' : 'de'
            await changeLang(lang.value)
        }
    })

    const changeLang = async (lang: string) => {
        isChangingLang.value = true
        await setI18nLocale(lang)
        setVeeLocale(lang)
        isChangingLang.value = false
    }

    return {
        toggle,
        lang,
        isChangingLang,
    }
}